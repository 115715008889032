import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Footer from "../Footer";
import Navbar from "../navbar";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  contact: Yup.string().required("Phone number is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  address1: Yup.string().required("Address Line 1 is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string()
    .matches(/^[1-9][0-9]{5}$/, "Invalid Pincode")
    .required("Pincode is required"),
  product: Yup.string().required("Please select a product"),
  quantity: Yup.number()
    .required("Quantity is required")
    .min(1, "Quantity must be at least 1"),
});

const Result = ({ status, orderId, message }) => (
  <div
    className={`alert ${
      status === "success" ? "alert-success" : "alert-danger"
    }`}
  >
    {status === "success" && (
      <h2 className="alert-heading">Order Placed Successfully!</h2>
    )}
    {/* {orderId && (
      <p >
        <strong>Order ID:</strong> {orderId}
      </p>
    )} */}
    <p
      className="col-lg-9 col-12 mx-auto mt-4"
      dangerouslySetInnerHTML={{ __html: message }}
    ></p>
  </div>
);

const CheckoutForm = () => {
  const [result, setResult] = useState(null);
  const [products, setProducts] = useState([]);

  // ... (useEffect remains the same)

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await fetch(
        "https://triverseadvertising.com/redcow/api/buy_now_api.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Form submission response:", data);

      setResult({
        status: data.status,
        orderId: data.order_id,
        message: data.message,
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      setResult({
        status: "error",
        message: "An error occurred while submitting the form",
      });
    }

    setSubmitting(false);
  };
  return (
    <>
      <Navbar />
      <section id="forms">
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="forms">
                <div className={`${result ? "d-none hidden" : ""} heading`}>
                  <h1>Check Out</h1>
                </div>

                <Formik
                  initialValues={{
                    name: "",
                    contact: "",
                    email: "",
                    address1: "",
                    city: "", // Default city
                    state: "", // Default state
                    pincode: "",
                    message: "",
                    product: "RedCow Gawa Ghee (500 ml) - MRP ₹405",
                    quantity: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <ul
                        className={`${
                          result ? "d-none hidden" : ""
                        } justify-content-between`}
                      >
                        {/* Company Name */}
                        <li>
                          <Field
                            type="text"
                            name="name"
                            placeholder="Name *"
                            className=""
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-danger"
                          />
                        </li>

                        {/* Phone Number */}
                        <li>
                          <Field
                            type="tel"
                            name="contact"
                            placeholder="Phone No. *"
                            className=""
                          />
                          <ErrorMessage
                            name="contact"
                            component="div"
                            className="text-danger"
                          />
                        </li>

                        {/* Email */}
                        <li>
                          <Field
                            type="email"
                            name="email"
                            placeholder="Email Id *"
                            className=""
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </li>
                        {/* Product */}
                        <li>
                          <Field as="select" name="product" className="">
                            <option value="">
                              RedCow Gawa Ghee (500 ml) - MRP ₹405
                            </option>

                            {/* Add more products here */}
                          </Field>
                          <ErrorMessage
                            name="product"
                            component="div"
                            className="text-danger"
                          />
                        </li>

                        {/* Quantity */}
                        <li>
                          <Field
                            type="number"
                            name="quantity"
                            placeholder="Quantity *"
                            className=""
                          />
                          <ErrorMessage
                            name="quantity"
                            component="div"
                            className="text-danger"
                          />
                        </li>

                        {/* Address Line 1 */}
                        <li>
                          <Field
                            type="text"
                            name="address1"
                            placeholder="Address *"
                            className=""
                          />
                          <ErrorMessage
                            name="address1"
                            component="div"
                            className="text-danger"
                          />
                        </li>

                        {/* City */}
                        <li>
                          <Field
                            type="text"
                            name="city"
                            placeholder="City *"
                            className=""
                          />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="text-danger"
                          />
                        </li>

                        {/* State */}
                        <li>
                          <Field
                            type="text"
                            name="state"
                            placeholder="State *"
                            className=""
                          />
                          <ErrorMessage
                            name="state"
                            component="div"
                            className="text-danger"
                          />
                        </li>

                        {/* Pincode */}
                        <li>
                          <Field
                            type="text"
                            name="pincode"
                            placeholder="Pincode *"
                            className=""
                          />
                          <ErrorMessage
                            name="pincode"
                            component="div"
                            className="text-danger"
                          />
                        </li>

                        {/* Submit Button */}
                        <li>
                          <input
                            type="submit"
                            value={isSubmitting ? "Submitting..." : "Submit"}
                            disabled={isSubmitting}
                            className="btn btn-primary"
                          />
                        </li>
                      </ul>

                      {/* Alert message on success */}
                      {result && <Result {...result} />}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default CheckoutForm;
