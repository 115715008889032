import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import { Myaccordian } from "../Myaccordian";
import Navbar from "../navbar";
import Aos from "aos";
import "aos/dist/aos.css";
import { useHistory, NavLink } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const ProductDetails = ({ match }) => {
  const slug = match.params.slug;

  const [pagedata, setPagedata] = useState([]);
  const [inputVisible, setInputVisible] = useState(false); // Controls input visibility
  const [loading, setLoading] = useState(false); // Controls loading state
  const [message, setMessage] = useState(""); // Stores the result message
  const [inputValue, setInputValue] = useState(""); // Stores input value
  const [error, setError] = useState(""); // Stores validation error

  // List of "in stock" pincodes
  const inStockPincodes = slug === "ghee" ? ["110075", "110001"] : [];

  let history = useHistory();

  const [data, setData] = useState([]);
  useEffect(() => {
    let mounted = true;

    Aos.init({ duration: 2000 });

    const loadData = async () => {
      const response = await axios.get(
        `https://triverseadvertising.com/redcow/api/product-details.php?url=${slug}`
      );

      if (response.data.length == 0) {
        console.log("redirect");
        history.push("/404");
        return false;
      }

      if (mounted) {
        setPagedata(response.data);
        setData(response.data.products.accordions);
      }
    };

    loadData();

    return () => {
      mounted = false;
    };
  }, []);
  if (pagedata.length == 0) {
    console.log("no data");
    return null;
  }

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  let URL = window.location.pathname;

  // Handles button click to show input
  const handleShowInput = () => {
    setInputVisible(true);
    setMessage(""); // Clear any previous message
  };

  // Validate pincode (assuming it should be exactly 6 digits)
  const validatePincode = (value) => {
    const pincodeRegex = /^[0-9]{6}$/;
    if (!pincodeRegex.test(value)) {
      setError("Pincode must be exactly 6 digits.");
      return false;
    }
    setError(""); // Clear any previous error
    return true;
  };

  // Handles form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validatePincode(inputValue)) {
      return; // Stop form submission if pincode is invalid
    }

    setLoading(true); // Start loading
    setMessage(""); // Clear the message

    // Simulate a 10-second delay
    setTimeout(() => {
      setLoading(false);

      // Check if the pincode is in stock
      if (inStockPincodes.includes(inputValue)) {
        setMessage("In stock! Redirecting to checkout...");

        // Simulate redirect to checkout page after a 2-second delay
        setTimeout(() => {
          window.location.href = "/checkout"; // Replace with the actual checkout URL
        }, 1500);
      } else {
        setMessage("Sorry, this product is currently out of stock.");
      }
    }, 2000);
  };
  return (
    <>
      <Navbar />
      <Helmet>
        <title>{pagedata.meta_title}</title>
        <meta name="description" content={pagedata.meta_descp} />
      </Helmet>
      {pagedata.products.map((product, index) => {
        return (
          <>
            <section
              className="product-detail m-0"
              style={{ backgroundImage: `url(${product.bg_img})` }}
              key={index}
            >
              <div className="container">
                <div className="product-container">
                  <div className="row">
                    <div className="col-md-5">
                      <div
                        className="produ_img"
                        data-aos="fade-left"
                        data-aos-delay="500"
                        data-aos-once="true"
                        data-aos-duration="1000"
                      >
                        <img src={product.img} alt={product.title} />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div
                        className="produ_cont"
                        data-aos="fade-right"
                        data-aos-delay="500"
                        data-aos-once="true"
                        data-aos-duration="1000"
                      >
                        <h4>{product.title}</h4>
                        <p>{product.descp}</p>
                        {pagedata.buy_now === "1" ? (
                          <div className="mt-5">
                            {!inputVisible ? (
                              <div className="text-md-start text-center mt-4">
                                <button
                                  className="Buy_now_button"
                                  onClick={handleShowInput}
                                  disabled={inputVisible} // Disable button after showing input
                                >
                                  <i className="fa fa-shopping-basket" />
                                  BUY NOW
                                </button>
                              </div>
                            ) : (
                              <div className="mt-4">
                                <form
                                  onSubmit={handleSubmit}
                                  className="text-lg-start text-center d-flex gap-2 flex-wrap"
                                >
                                  {/* Pincode Input */}
                                  <input
                                    type="text"
                                    className={`form-control_pincode d-inline-block w-50 ${
                                      error ? "is-invalid" : ""
                                    }`}
                                    placeholder="Enter Your Pincode"
                                    value={inputValue}
                                    onChange={(e) =>
                                      setInputValue(e.target.value)
                                    }
                                    required
                                  />

                                  {/* Submit Button */}
                                  <button
                                    type="submit"
                                    className="Buy_now_button"
                                  >
                                    {loading
                                      ? "Checking..."
                                      : "Check Availability & Buy"}
                                  </button>
                                </form>

                                {/* Validation Error */}
                                {error && (
                                  <div className="text-danger mt-2">
                                    {error}
                                  </div>
                                )}

                                {/* Display the result message */}
                                {message && (
                                  <div className="text-center mt-3">
                                    <div
                                      className={`message ${
                                        message.includes("In stock!")
                                          ? "in-stock-message"
                                          : "out-of-stock-message"
                                      }`}
                                    >
                                      {message.includes("In stock!") ? (
                                        <i className="fas fa-check-circle"></i> // Success icon
                                      ) : (
                                        <i className="fas fa-exclamation-triangle"></i> // Error icon
                                      )}
                                      {message}
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <ul className="tick">
                    {product.icons.map((icon, index) => {
                      return (
                        <li
                          data-aos="fade-up"
                          key={`icon-${index}`}
                          data-aos-delay={icon.delay}
                          data-aos-once="true"
                          data-aos-duration="1000"
                        >
                          <img src={icon.icon_img} alt={icon.icon_val} />
                          <span>{icon.icon_val}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </section>

            <section className="accordians">
              <div className="container">
                <div className="product-container ">
                  <div className="accordian ">
                    {product.accordions.map((curElem) => {
                      const { id } = curElem;
                      return <Myaccordian key={id} {...curElem} />;
                    })}
                  </div>
                </div>
              </div>
            </section>
          </>
        );
      })}
      {URL == "/our-products/ice-cream" ? (
        <section id="icereammenu">
          <div className="container">
            <a
              href="https://www.redcowdairy.in/icecream"
              target="_blank"
              className="knowmore"
            >
              <span>Know more about ice cream</span>
            </a>
          </div>
        </section>
      ) : null}
      <section>
        <div
          className="heading mb-5 pb-5"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
          data-aos-duration="500"
        >
          <h1>Other Products</h1>
        </div>
        <div id="proslider">
          <div className="container">
            <div className="product-container">
              <div className="proslider">
                <Slider {...settings}>
                  {pagedata.rpro.map((product, index) => {
                    return (
                      <div>
                        {product.plinkVal != "" ? (
                          <a href={product.plinkVal} target="_blank">
                            <img
                              src={product.img}
                              alt="img"
                              key={`img-${index}`}
                            />
                          </a>
                        ) : (
                          <NavLink
                            exact
                            to={`/our-products/${product.slug}`}
                            target="_blank"
                          >
                            <img
                              src={product.img}
                              alt="img"
                              key={`img-${index}`}
                            />
                          </NavLink>
                        )}
                      </div>
                    );
                  })}
                </Slider>
              </div>
              <div className="cta">
                <NavLink to="/dealers-enquiry">
                  For Business Related Enquiry
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
